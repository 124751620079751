<template>
    <client-page>
        <sub-visual
        sh="회사소개"
        sc="에프원시큐리티가 여러분의 정보를 함께 보호하겠습니다."
        tabActive="조직도"
        icon="/images/sub/visual/sv1-icon.svg"
        bg="/images/sub/visual/sv1.png">
        </sub-visual>

        <section class="sub-section sub-section--last">
            <v-container>
                <h3 class="sub-tit--lg mb-28 mb-lg-54" data-aos="fade-up">
                    <span class="color-point">F1 Security</span> 는 <br class="d-none d-md-block">
                    다양한 구성원 으로 이루어져 있습니다
                </h3>
                <div class="pa-16 pa-md-60 w-100 bg-grey radius-md">
                    <v-img class="d-none d-md-block mx-auto" max-width="865" src="/images/sub/about/org/org.png"></v-img>
                    <v-img class="d-block d-md-none mx-auto" max-width="524" src="/images/sub/about/org/org-mo.png"></v-img>
                </div>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
export default {
    components: {
        ClientPage,
        SubVisual,
    },
};
</script>

<style lang="scss" scoped>
</style>